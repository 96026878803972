import React from 'react';
import SubjectCard from 'components/card/SubjectCard';
import KirdContainer from 'components/layouts/KirdContainer';
import SlidesTitle from './SlidesTitle';
import SwiperCore, {Navigation} from 'swiper';
import {Swiper, SwiperSlide} from 'swiper/react';
import 'swiper/swiper-bundle.min.css';
import style from './SubjectSlides.module.css';
import {Subject} from "../../components/types/Subject";
import SubjectCardForExplore from 'components/card/SubjectCardForExplore';

SwiperCore.use([Navigation]);

interface Props {
    id?: string,
    title: string,
    list: any[],
    referer?: string
}

export default function SubjectSlides(props: Props) {
    const {id, title, list, referer} = props;

    return (
        <KirdContainer className="mt-10">
            <SlidesTitle title={title} id={id}/>
            <div className={style.processContGroup}>
                <Swiper speed={500}
                        slidesPerView='auto'
                        spaceBetween={16}
                        navigation={{prevEl: `#${id} .btnPrev`, nextEl: `#${id} .btnNext`}}
                        breakpoints={{
                            1024: {
                                slidesPerView: 4,
                                spaceBetween: 35,
                            },
                            320: {
                                slidesPerView: 2,
                                spaceBetween: 16,
                            }
                        }}
                >
                    {list?.map((subject: Subject) => (
                        <SwiperSlide key={subject?.id}>
                            {/* <SubjectCard subject={subject} referer={referer}/> */}
                            <SubjectCardForExplore subject={subject} isQuerySend />
                        </SwiperSlide>
                    ))}
                </Swiper>
            </div>
        </KirdContainer>
    );
};

