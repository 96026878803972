import {useTranslation} from "next-i18next";
import styles from './ChangePasswordGuide.module.css';
import clsx from 'clsx';
import {useEffect, useState} from "react";
import {useRouter} from "next/router";

interface Props {
    user: any;
};

export default function ChangePasswordGuide(props: Props) {
    const {t} = useTranslation();
    const {isReady, push} = useRouter();
    const {user} = props;

    // 팝업 활성화 여부
    const [openGuide, setOpenGuide] = useState<boolean>(false);

    // 비밀번호 변경 페이지 이동
    const changePassword = () => {
        push({
            pathname: '/my/profile',
            query: {
                changePassword: true,
            }
        });
    };

    // 90일간 보지 않기
    const setStopGuide = () => {
        // TODO - 90일간 보지 않기
    };

    useEffect(() => {
        if (isReady) {
            // TODO - 비밀번호 갱신 기간 초과 여부 확인
            //setOpenGuide(true);
        }
    }, [user]);

    return (
        <div className={clsx(styles.modPopup, styles.modal, {[styles.on]: openGuide})}>
            <div className={clsx(styles.popupArea, "w-[560px] h-auto")}>
                <div className={styles.popupInMotion}>
                    <div className={styles.popupTitGroup}>
                        <div className={styles.title}>{t('비밀번호 변경 안내')}</div>
                    </div>
                    <div className={clsx(styles.popupContGroup, styles.noPd)}>
                        <div className={styles.lytPwChange}>
                            <div className={styles.pwChangeArea}>
                                <div className={styles.pwChangeCont}>
                                    <p className={styles.message}>{t('비밀번호를')} <span className={styles.tRed}>{t('90일 동안 변경하지 않으셨습니다')}.</span><br/>
                                        {t('고객님의 소중한 개인정보 보호를 위해')}<br/>
                                        {t('지금 비밀번호를 변경하시겠습니까')}?</p>
                                    <button className={styles.btnPwChange} type="button" onClick={changePassword}>{t('비밀번호 변경하기')}</button>
                                </div>
                                <div className={styles.pwChangeBtn}>
                                    <button className={styles.noView} type="button" onClick={setStopGuide}>{t('90일간 그만 보기')}</button>
                                    <button className={styles.close} type="button" onClick={() => setOpenGuide(false)}>{t('닫기')}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
