import Link from "next/link";
import styles from './ForumCard.module.css';
import {format} from "date-fns";
import React, {useMemo} from 'react';
import {Meeting} from "components/types/Meeting";
import {useTranslation} from "next-i18next";
import {useRouter} from "next/router";
import {MeetingType} from "shared/utils/CommonCode";
import Thumbnail from "../common/Thumbnail";
import {dateToString} from "shared/utils/DateUtil";

interface Props {
    meeting: Meeting;
};

export default function MeetingCard(props: Props) {
    const {t} = useTranslation();
    const {push} = useRouter();
    const {meeting} = props;

    const session = useMemo(() => {
        let startDateTime = null;
        let endDateTime = null;

        if (meeting?.sessions?.length > 0) {
            startDateTime = new Date(meeting.sessions[0].startDateTime);
            endDateTime = new Date(meeting.sessions[0].endDateTime);
        }

        return {
            startDateTime: startDateTime,
            endDateTime: endDateTime,
        }
    }, [meeting]);

    return (
        <div className={styles.cptForumFull}>
            <Link href={`/community/meeting/${meeting.type === MeetingType.FORUM ? 'forum' : 'seminar'}/${meeting.id}`}>
                <a className={styles.thumLink}>
                    <div className={styles.thumImgGroup}>
                        <div className={styles.imgBox}>
                            <Thumbnail
                                id={meeting.thumbnail?.id}
                                alt={meeting.thumbnail?.name}
                                className="!w-[443px] !h-[443px]"
                            />
                        </div>
                        {/* 썸네일 양식에 맞추지 않을 경우 문제가 되어 삭제 처리 */}
                        {/*
                        <div className={styles.imgIcon}>
                            {meeting.type === MeetingType.FORUM && <img src="/images/icon/ico-64-category-forum.png" alt="forum"/>}
                            {meeting.type === MeetingType.SEMINAR && <img src="/images/icon/ico-64-category-hub-white.png" alt="seminar"/>}
                        </div>
                        <div className={styles.imgInfo}><h4>{meeting.name}</h4></div>
                        */}
                    </div>
                    <div className={styles.thumContGroup}>
                        <div className={styles.thumTitle}><h4>{meeting.name}</h4></div>
                        <div className={styles.thumDate}>
                            <dl className={styles.schedule}>
                                <dt>{t('신청기간')}</dt>
                                <dd>{dateToString(meeting.registrationStartDateTime, 'yyyy-MM-dd HH:mm:ss')} ~ {dateToString(meeting.registrationEndDateTime, 'yyyy-MM-dd HH:mm:ss')}</dd>
                            </dl>
                            <dl className={styles.schedule}>
                                <dt>{t(meeting.type === MeetingType.FORUM ? '학습기간' : '학습기간')}</dt>
                                <dd>{dateToString(session.startDateTime, 'yyyy-MM-dd HH:mm:ss')} ~ {dateToString(session.endDateTime, 'yyyy-MM-dd HH:mm:ss')}</dd>
                            </dl>
                        </div>
                    </div>
                </a>
            </Link>
        </div>
    );
};
