import React, { forwardRef, useCallback, useState } from 'react';
import SlidesTitle from './SlidesTitle';
import SwiperCore, {Navigation} from 'swiper';
import {Swiper, SwiperSlide} from 'swiper/react';
import 'swiper/swiper-bundle.min.css';
import KirdContainer from "components/layouts/KirdContainer";
import style from './NewChannelSlides.module.css';
import SubscriptionHomeNewChannelContentCard from 'partials/subscription/home/SubscriptionHomeNewChannelContentCard';
import { ChannelContent } from 'components/types/ChannelContent';
import { objCookiesDay } from 'shared/utils/CommonCode';
import { useRouter } from 'next/router';
import KirdModal from 'components/common/KirdModal';
import { Button, Grow } from '@material-ui/core';
import Thumbnail from 'components/common/Thumbnail';
import { TransitionProps } from '@material-ui/core/transitions';
import { useTranslation } from 'next-i18next';

SwiperCore.use([Navigation]);

interface Props {
    id?: string;
    title: string;
    list: Array<ChannelContent>;
}

const Transition = forwardRef(function Transition(
    props: TransitionProps & { children?: React.ReactElement<any, any> },
    ref: React.Ref<unknown>,
  ) {
    return (
        <Grow in={true} ref={ref} {...props} timeout={{appear: 0, enter: 500, exit: 200}}/>
    );
  });

export default function NewChannelSlides(props: Props) {
    const {id, title, list} = props;
    const { t } = useTranslation();
    const { pathname, isReady, query, push, replace } = useRouter();
    
    // 비로그인시 팝업
    const [openLoginInfo, setOpenLoginInfo] = useState<boolean>(false);
    // 카드 데이터
    const [getCardInfo, setGetCardInfo] = useState<ChannelContent>();

        // 모달 팝업 닫기
    const closeModal = useCallback((setter: Function) => setter(false), []);
    const [cookie, setCookie] = useState<boolean>(false);
    const close = () => {
        if (cookie) {
        objCookiesDay.addDay({ path: "/", name: "loginPopup", value: "loginPopup" });
        }
        setOpenLoginInfo(false);
    };

    const goNoLoginPage = () => {
        replace({
            pathname: `/subscription/channel/[cid]/content`,
            query: {
                ...query,
                cid: String(getCardInfo.channel?.id),
                contentId: getCardInfo.id,
            }
        });
    }

    const handleClickOnOverlay = () => {
        document.cookie = "previousUrl=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
        document.cookie = `previousUrl=${encodeURIComponent("/")}; path=/;`;
        push("/login");
    }; 

    return (
        <KirdContainer className="mt-10">
            <SlidesTitle title={title} id={id}/>
            <div className={style.processContGroup}>
                <Swiper speed={500}
                        slidesPerView='auto'
                        spaceBetween={16}
                        navigation={{prevEl: `#${id} .btnPrev`, nextEl: `#${id} .btnNext`}}
                        breakpoints={{
                            1024: {
                                slidesPerView: 4,
                                spaceBetween: 35,
                            },
                            320: {
                                slidesPerView: 2,
                                spaceBetween: 16,
                            }
                        }}
                >
                    {list?.map((channelContent: ChannelContent) => (
                        <SwiperSlide key={channelContent.id}>
                            <SubscriptionHomeNewChannelContentCard channelContent={channelContent} setOpenLoginInfo={setOpenLoginInfo} setGetCardInfo={setGetCardInfo}/>
                        </SwiperSlide>
                    ))}
                </Swiper>
            </div>
            {/* 로그인 안되었을 떄 모달 띄우기 */}
            <KirdModal
                title={t('로그인 바로가기')}
                fullWidth={true}
                maxWidth="sm"
                transitionComponent={Transition}
                open={openLoginInfo}
                onClose={() => closeModal(setOpenLoginInfo)}
            >
                <div style={{width: "100%", padding: "20px"}}>
                  <div style={{width: "100%", display: "flex", justifyContent: "space-between", marginBottom: "30px"}}>
                    <div style={{width: "45%", display: "flex", justifyContent:"center"}}>
                    <Thumbnail
                      id={getCardInfo?.thumbnail?.id}
                      alt={getCardInfo?.name}
                    />
                    </div>
                    <div style={{width: "45%", display: "flex", justifyContent:"center", flexDirection:"column"}}>
                      <p>무료 회원가입 후 "<b>로그인</b>" 하시면</p>
                      <p>참가증을 발급 받을 수 있습니다.</p>
                    </div>
                  </div>
                  <p>- 비로그인 진행 시 참가증 발급 불가 </p>
                  <p>- 로그인 시 [MY - 학습이력 - 콘텐츠] 에서 참가증 발급</p>
                  <div style={{width: "100%", display: "flex", justifyContent: "space-between", margin: "30px 0"}}>
                    <Button
                      variant="contained"
                      color="default"
                      style={{width: "48%"}}
                      onClick={goNoLoginPage}
                    >그냥 학습하기</Button>
                    <Button
                      variant="contained"
                      color="primary"
                      style={{width: "48%"}}
                      onClick={handleClickOnOverlay}
                    >로그인하기</Button>
                  </div>
                  <div style={{width: "100%", display: "flex", justifyContent: "space-between"}}>
                  <label>
                    <input
                      type="checkbox"
                      checked={cookie}
                      onChange={() => setCookie(!cookie)}
                      style={{overflow: "visible", opacity: "1", width: "30px"}}
                    />
                    오늘 하루 열지 않기
                  </label>
                    <Button
                      variant="contained"
                      color="default"
                      onClick={close}  
                    >닫기</Button>
                  </div>
                </div>
            </KirdModal>
        </KirdContainer>
    );
};

