import styles from './ChannelCard.module.css';
import {useTranslation} from "next-i18next";
import Thumbnail from "../common/Thumbnail";
import React from "react";
import {useRouter} from "next/router";
import {Channel} from "components/types/Channel";

interface Props {
    channel: Channel;
};

export default function ChannelCard(props: Props) {
    const {t} = useTranslation();
    const {push} = useRouter();
    const {channel} = props;

    return (
        <div className={styles.cptRoundthum} tabIndex={0}>
            <a className={styles.roundthumLink}>
                <div className={styles.roundthumImgGroup}>
                    <div className={styles.imgBox}>
                        <Thumbnail
                            id={channel.thumbnail?.id}
                            alt={channel.name}
                            onClick={() => push(`/subscription/channel/${channel.id}`)}
                        />
                    </div>
                </div>
                <div className={styles.roundthumContGroup}>
                    <div className={styles.roundthumTitle}>
                        <h4>{channel.name}</h4>
                    </div>
                    <div className={styles.roundthumHost}>
                        <p className={styles.name}>{channel.influencer?.name}</p>
                    </div>
                    <div className={styles.roundthumInfo}>
                        <ul>
                            <li className={styles.infoItem}>
                                <span className={styles.hide}>{t('조회수')}:</span>
                                <span className={styles.view}>{(channel.views + channel.nonLoginViews)?.toLocaleString() || 0}</span>
                            </li>
                            <li className={styles.infoItem}>
                                <span className={styles.hide}>{t('리스트')}:</span>
                                <span className={styles.list}>{channel.numberOfContent?.toLocaleString() || 0}</span>
                            </li>
                        </ul>
                    </div>
                </div>
            </a>
            <button type="button" className={styles.btnMore} onClick={() => push(`/subscription/channel/${channel.id}`)} title={`${channel.name} 더보기`} />
        </div>
    );
};
