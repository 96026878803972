import React from 'react';
import MeetingCard from 'components/card/MeetingCard';
import KirdContainer from 'components/layouts/KirdContainer';
import SlidesTitle from './SlidesTitle';
import SwiperCore, {Navigation} from 'swiper';
import {Swiper, SwiperSlide} from 'swiper/react';
import 'swiper/swiper-bundle.min.css';
import {Meeting} from "components/types/Meeting";
import style from './MeetingSlides.module.css';

SwiperCore.use([Navigation]);

interface Props {
    id?: string,
    title: string,
    list: Array<Meeting>,
}

export default function MeetingSlides(props: Props) {
    const {id, title, list} = props;

    return (
        <KirdContainer className="mt-10">
            <SlidesTitle title={title} id={id}/>
            <div className={style.processContGroup}>
                <Swiper
                    speed={500}
                    slidesPerView='auto'
                    spaceBetween={16}
                    navigation={{prevEl: `#${id} .btnPrev`, nextEl: `#${id} .btnNext`}}
                    breakpoints={{
                        1024: {
                            slidesPerView: 3,
                            spaceBetween: 35,
                        }
                    }}
                >
                    {list?.map((meeting: Meeting) => (
                        <SwiperSlide key={meeting.id}>
                            <MeetingCard meeting={meeting}/>
                        </SwiperSlide>
                    ))}
                </Swiper>
            </div>
        </KirdContainer>
    );
};
